import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultBuyerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_buyer_banner', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListBuyerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_buyer_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getBuyerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_buyer_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBuyerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_buyer_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editBuyerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_buyer_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBuyerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_buyer_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    buyerBannerOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/buyer_banner_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageBuyerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_buyer_banner/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
